@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import './animation.css';
@import './fonts.css';

#__next {
  min-height: 100%;
}
