/* Title animation */

@-webkit-keyframes svg-text-anim {
  40% {
    stroke-dashoffset: 0;
    fill: transparent;
  }
  60% {
    stroke-dashoffset: 0;
    fill: #4d4e52;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #4d4e52;
  }
}

@keyframes svg-text-anim {
  40% {
    stroke-dashoffset: 0;
    fill: transparent;
  }
  60% {
    stroke-dashoffset: 0;
    fill: #4d4e52;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #4d4e52;
  }
}
